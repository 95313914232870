import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaInfoCircle, FaCode, FaEnvelope, FaAngleDown, FaAngleDoubleUp } from 'react-icons/fa';
import { BsDiamondFill } from 'react-icons/bs';

import nameFirst from '../images/First-Sortelo.png';
import nameLast from '../images/Last-Sortelo.png';
import feedReaderImage from '../images/FeedReader_thumb.png';
import radioPlayerImage from '../images/RadioPlayer_thumb.png';
import Contact from '../components/site/Contact';

function HomePage(props) {
    const topSection = useRef(); 
    const aboutSection = useRef();
    const projectsSection = useRef();
    const contactSection = useRef();
    
    const [ isAboutVisible, setAboutVisible ] = useState(false);
    const [ isProjectsVisible, setProjectsVisible ] = useState(false);
    const [ isContactVisible, setContactVisible ] = useState(false);

    const observerCallback = (entries) => {
        let sectionId = entries[0].target.id;
        if (sectionId === 'about' && !isAboutVisible){
            setAboutVisible(true);
        }
        else if (sectionId === 'projects' && !isProjectsVisible) {
            setProjectsVisible(true);
        }
        else if (sectionId === 'contact' && !isContactVisible) {
            setContactVisible(true);
        }
    }

    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: .4
    }

    useEffect(() => {
        //component mount
        document.title = props.title;
        // document.body.style.overflow = 'hidden';

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(aboutSection.current);
        observer.observe(projectsSection.current);
        observer.observe(contactSection.current);
    });

    const handleAboutJump = () => {
        let elementTop = topSection.current.offsetTop;
        window.scrollTo(0, elementTop);        
    }

    const handleProjectsJump = () => {
        let elementTop = projectsSection.current.offsetTop;
        window.scrollTo(0, elementTop);        
    }

    const handleContactJump = () => {
        let elementTop = contactSection.current.offsetTop;
        window.scrollTo(0, elementTop);
    }

    // let githubIconStyle = {
    //     color: 'white',
    //     fontSize: '18px'
    // }

    let readerTileStyle = {
        backgroundImage: `url(${feedReaderImage})`
    };

    let radioTileStyle = {
        backgroundImage: `url(${radioPlayerImage})`
    }

    const renderAboutSection = () => {
        if (isAboutVisible) {
            return (
                <React.Fragment>
                    <div className="aboutContent">
                        <div className="header">
                            <p className="headerText slide-left">ABOUT</p>
                            <hr className="headerUnderline slide-left" />
                        </div>
                        <div className="fade-in">
                            <p className="contentDescription">
                                Hello! My name is Mike, and I'm a full stack developer in the midwest U.S. with 
                                work experience in the healthcare and printing industries. I'm an
                                outdoor enthusiast, an aspiring minimalist, and I'm passionate about learning.
                            </p>
                            <div>
                                <div className="divExperienceList">
                                    <p className="experienceListHeader">Experience</p>
                                    <div className="experienceList">
                                        <ul>
                                            <li>
                                                <BsDiamondFill className="experienceBullet" />
                                                Desktop to web app conversions
                                            </li>
                                            <li>
                                                <BsDiamondFill className="experienceBullet" />
                                                Service oriented architectures
                                            </li>
                                            <li>
                                                <BsDiamondFill className="experienceBullet" />
                                                Automation
                                            </li>
                                            <li>
                                                <BsDiamondFill className="experienceBullet" />
                                                Code optimization
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="divTechList">
                                    <p className="techListHeader">Expertise</p>
                                    <div className="techList">
                                        <ul>
                                            <li>HTML, CSS</li>
                                            <li>Javascript</li>
                                            <li>React JS</li>
                                            <li>.NET, C#</li>
                                        </ul>
                                        <ul>
                                            <li>Services, API's</li>
                                            <li>Microsoft SQL Server</li>
                                            <li>Windows &amp; Linux</li>
                                        </ul>                        
                                    </div>
                                </div>                                
                            </div>                            
                        </div>                    
                    </div>
                    <div className="aboutFooter">
                        <FaAngleDown className="aboutNextSectionButton"
                                        onClick={handleProjectsJump} />
                    </div>
                </React.Fragment>
            );
        }
    }

    const renderProjectsSection = () => {
        if (isProjectsVisible) {
            return (
                <React.Fragment>
                    <div className="projectsContent">
                        <div className="header">
                            <p className="headerText slide-right">PROJECTS</p>
                            <hr className="headerUnderline slide-right" />
                        </div>
                        <div className="projectDescription">
                            <p className="projectText">
                                Below are some of my personal projects. 
                                Source code can be found on&nbsp;
                                <a href="https://github.com/m1k3f" target="_blank" rel="noopener noreferrer">
                                    Github
                                </a>.
                            </p>
                        </div>
                        <div className="projectTiles fade-in">
                            <Link to="/reader">
                                <div className="projectTile" style={readerTileStyle}>
                                    <div className="projectTileText">
                                        <h5 style={{margin: 0}}>Feed Reader</h5>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/stream">
                                <div className="projectTile" style={radioTileStyle}>
                                    <div className="projectTileText">
                                        <h5 style={{margin: 0}}>Radio Player</h5>
                                    </div> 
                                </div>
                            </Link>
                        </div>                    
                    </div>
                    <div className="projectsFooter">
                        <FaAngleDown className="projectsNextSectionButton"
                                        onClick={handleContactJump} />
                    </div>
                </React.Fragment>
            );
        }
    }

    const renderContactSection = () => {
        if (isContactVisible) {
            return (
                <React.Fragment>
                    <div className="header">
                        <p className="headerText slide-left">CONTACT</p>
                        <hr className="headerUnderline slide-left" />                        
                    </div>
                    <div>
                        <p className="contactText">
                            Feel free to contact me about interesting projects, hobbies, 
                            and work opportunities.
                        </p>
                    </div>
                    <div className="fade-in">                        
                        <Contact />
                    </div>                    
                </React.Fragment>
            );
        }
    }

    return (
        <div className="homePage">
            <div className="heroImage" ref={topSection} />
            {/* <section id="top" className="topSection" ref={topSection}>                 */}
            <div className="navBar">
                <div className="navBarLeft">
                    <img className="nameImageItem" src={nameFirst} alt="" />
                    <img className="nameImageItem" src={nameLast} alt="" />
                </div>
                <div className="navBarRight">
                    <button className="pageLink" title="About" onClick={handleAboutJump}>
                        <FaInfoCircle className="pageLinkImage" />
                        <p>About</p>
                    </button>
                    <button className="pageLink" title="Projects" onClick={handleProjectsJump}>
                        <FaCode className="pageLinkImage" />
                        <p>Projects</p>
                    </button>
                    <button className="pageLink" title="Contact" onClick={handleContactJump}>
                        <FaEnvelope className="pageLinkImage" />
                        <p>Contact</p>
                    </button>
                </div>
            </div>
            {/* </section> */}
            <section id="about" className="aboutSection" ref={aboutSection}>
                {renderAboutSection()}
            </section>
            <section id="projects" className="projectsSection" ref={projectsSection}>                
                {renderProjectsSection()}
            </section>
            <section  id="contact" className="contactSection" ref={contactSection}>                
                {renderContactSection()}
            </section>
            <section id="footer" className="footerSection">
                <div className="contactFooter">
                    <FaAngleDoubleUp className="topButton"
                                    onClick={handleAboutJump} />
                </div>
            </section>
        </div>
    );
}

export default HomePage;