import React, { Component } from 'react';
import {BrowserRouter as Router, Route } from 'react-router-dom';

import HomePage from './pages/HomePage';
import RadioPage from './pages/RadioPage';
import ReaderPage from './pages/ReaderPage';
import './css/App.css';
import './css/fontawesome/all.css';
import './css/animation.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      home: {
        title: 'findmike.dev',
        path: '/'
      },
      reader: {
        title: 'Feed Reader',
        path: '/reader'
      },
      streamer: {
        title: 'Radio',
        path: '/stream'
      }
    }
  }

  render() {
    const { home, reader, streamer } = this.state;

    return(
      <div className="app fade-in">
        <Router>
          <Route path={home.path} exact render={() => <HomePage title={home.title} />} />
          <Route path={reader.path} exact render={() => <ReaderPage title={reader.title} />} />
          <Route path={streamer.path} exact render={() => <RadioPage title={streamer.title} />} />
        </Router>
      </div>
    );
  }
}

export default App;
