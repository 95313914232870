import React from 'react';

import { Reader } from '../components/reader/Reader';

function ReaderPage (props) {
    return (
        <Reader title={props.title} />
    );
}

export default ReaderPage;