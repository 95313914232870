import React from 'react';

function Contact(props) {

    return (
        <div className="divContact">
            <form className="contactForm" acceptCharset="utf-8" 
                    action="https://formspree.io/f/mgerekzw" method="post">
                {/* <label htmlFor="txtName">Name</label> */}
                <input id="txtName" name="name" className="inputField" placeholder="Name"
                        type="text" required />
                {/* <label htmlFor="txtEmail">Email Address</label> */}
                <input id="txtEmail" name="email" className="inputField" placeholder="Email Address"
                        type="text" required />
                {/* <label htmlFor="txtMessage">Message</label> */}
                <textarea id="txtMessage" name="message" className="messageText" placeholder="Message"
                            rows="8" required />
                <input type="hidden" id="txtSubject" name="subject" value="Contact Form Submission" />
                <div className="divSubmit">
                    <input className="submitButton" type="submit" value="Submit" />
                </div>
            </form>
        </div>
    );
}

export default Contact;