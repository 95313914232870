import React from 'react';

import Radio from '../components/radio/Radio';

function RadioPage(props) {
    return (
        <Radio title={props.title} />
    );
}

export default RadioPage;